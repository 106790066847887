import React, { useState } from 'react';
import {
    IconButton,
    Flex,
    Textarea,
    Tooltip,
    Box,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { MailIcon } from '../../../../assets/icons/Mail';
import { AiOutlineFile } from 'react-icons/ai';
import { useSendCsvReportViaEmailMutation, useSendPdfReportViaEmailMutation } from '../../../locations/api/locationsApi';
import CustomModal from './CustomModal';
import EmailInput from './EmailInput';
import { blobToBase64 } from '../../../../utils/helpers';
import { toast } from 'react-toastify';

interface Props {
    reportData?: any;
    handleClick?: any;
    reportName: string;
    disabled: boolean;
    startDate: any;
    endDate: any;
    reportType: string;
}

function formatDate(date) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date?.toLocaleDateString('en-GB', options).replace(',', '');
}

export default function SendEmail(props: Props) {
    const { reportData, handleClick, reportName, disabled, startDate, endDate, reportType } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [emails, setEmails] = useState([]);

    const [sendCsvReportViaEmail, { isLoading: isSendCsvLoading }] =
        useSendCsvReportViaEmailMutation()

    const [sendPdfReportViaEmail, { isLoading: isSendPdfLoading }] =
        useSendPdfReportViaEmailMutation()

    const {
        register,
        reset,
        watch,
        handleSubmit,
        formState: { errors, isValid: formIsValid },
    } = useForm();


    const handleSend = async () => {
        const toastId = toast.info('Sending report...', {
            style: { padding: '10px 20px' },
            autoClose: false,
            isLoading: true,
            progress: undefined,
        })
        try {
            let data = reportData
            if (!data && handleClick) {
                data = await handleClick()
            }

            if (reportType === "csv") {
                await sendCsvReportViaEmail({
                    rows_data: data,
                    file_name: reportName,
                    emails: emails,
                    description: watch("composeEmail"),
                }).unwrap()
            } else if (reportType === "pdf") {
                const base64Pdf = await blobToBase64(data);
                await sendPdfReportViaEmail({
                    pdf_data: base64Pdf,
                    file_name: reportName,
                    emails: emails,
                    description: watch("composeEmail"),
                }).unwrap()
            }
            toast.update(toastId, {
                render: 'Report sent successfully!',
                type: 'success',
                isLoading: false,
                autoClose: 3000,
            })
        } catch (_error) {
            toast.update(toastId, {
                render: `Failed to send report`,
                type: 'error',
                isLoading: false,
                autoClose: 3000,
            })
        }

        onClose()
        setEmails([])
        reset()
    };

    const handleClose = () => {
        onClose()
        setEmails([])
        reset()
    };

    return (
        <>
            <Tooltip label="Send Report via email" hasArrow placement="top">
                <Box>
                    <IconButton
                        size="sm"
                        disabled={disabled}
                        background="none"
                        aria-label="send email"
                        _focus={{ boxShadow: 'none' }}
                        icon={<MailIcon boxSize="24px" />}
                        onClick={onOpen}
                    />
                </Box>
            </Tooltip>
            <CustomModal
                title="Send report via email"
                tooltipLabel="Please provide recipient email/s and a description before sending"
                handleSend={handleSubmit(() => handleSend())}
                isDisabled={!formIsValid || emails.length === 0}
                isLoading={isSendCsvLoading || isSendPdfLoading}
                isOpen={isOpen}
                onClose={handleClose}
                buttonName="Send"
            >
                <Flex display="flex" direction="column" gap={3} as="form">
                    <EmailInput emails={emails} setEmails={setEmails} />
                    <Textarea
                        w="full"
                        bg="white"
                        border="1px"
                        height="118px"
                        resize="none"
                        textAlign="left"
                        fontSize="18px"
                        fontWeight="normal"
                        id={'composeEmail'}
                        {...register('composeEmail', { required: true })}
                        placeholder="Compose email"
                    />
                    <Box bg="#f5f6f7" height="61px" w="100%" p={3} borderRadius={5}>
                        <Flex display="flex" gap={3}>
                            <AiOutlineFile size="40px" color="grey" />
                            <Flex display="flex" direction="column">
                                <Text style={{ fontWeight: 700, fontSize: 14 }}>
                                    {reportName}.{reportType}
                                </Text>
                                {!disabled && (
                                    <p style={{ fontWeight: 400, fontSize: '12px' }}>
                                        {`${formatDate(startDate)} - ${formatDate(endDate)}`}
                                    </p>
                                )}
                            </Flex>
                        </Flex>
                    </Box>
                </Flex>
            </CustomModal>
        </>
    );
}

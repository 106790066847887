import { Flex, Spinner, Stack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { formatDate } from '../../../../utils/helpers'
import { ILocationResult } from '../../api/locations'
import { IBillingLocationReport } from '../../api/types'
import { BillingReportBody } from './BillingReport/BillingReportBody'
import { TrendReportHeader } from './PdfComponents/TrendReportHeader'

type Props = {
  fromDate: Date
  toDate: Date
  locations: ILocationResult[]
  data: IBillingLocationReport[]
  reportEnergyType: number
  isDownloading: boolean
}

export const BillingReportPdf = ({
  toDate,
  fromDate,
  reportEnergyType,
  data,
  isDownloading,
  locations,
}: Props) => {
  
  const reportStartDate = useMemo(() => {
    return formatDate(fromDate)
  }, [data])

  const reportEndDate = useMemo(() => {
    return formatDate(toDate)
  }, [data])

  return (
    <Stack width="100%" height="100%" alignItems="center">
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        {isDownloading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
        <>
            <TrendReportHeader
              locations={locations}
              startDate={reportStartDate}
              endDate={reportEndDate}
              classification={"Billing Report"}
            />
            <BillingReportBody
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              classification={reportEnergyType}
              data={data}
            />
          </>
        )}
      </Flex>
    </Stack>
  )
}

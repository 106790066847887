import { Box, Stack } from '@chakra-ui/react'
import { GenerateTrendChart } from './GenerateTrendChart/GenerateTrendChart'
import { PdfTable } from './PdfTable/PdfTable'
import { TrendReportHeader } from './TrendReportHeader'
import { NewPageHeader } from './NewPageHeader'
import { IReportTotalResult } from '../../../api/trendReportPdf'
import { ILocationResult } from '../../../api/locations'
import { ReportPeriodType } from '../../GenerateReport'

type TrendReportPdfProps = {
  reportStartDate: string,
  reportEndDate: string,
  locations: ILocationResult[],
  data: IReportTotalResult,
  reportPeriodType: ReportPeriodType,
  reportEnergyType: string
}

const TotalTrendReportPdf = (props: TrendReportPdfProps) => {
  const { locations, data, reportPeriodType, reportStartDate, reportEndDate, reportEnergyType } = props

  return (
    <Stack width={'100%'} alignItems="center" mb={10}>
      <Box height={'1100px'} width={'100%'}>
        <TrendReportHeader
          locations={locations}
          startDate={reportStartDate}
          endDate={reportEndDate}
          classification='Trend report'
          errorMessage={data?.message}
        />
      </Box>
      <Box>
        <Box  className="trendChart">
          <NewPageHeader
            reportType={''}
            period={`${reportStartDate} - ${reportEndDate}`}
          />
          <GenerateTrendChart data={data.result} reportPeriodType={reportPeriodType} reportEnergyType={reportEnergyType} />
        </Box>
      </Box>
      <Box style={{ marginTop: '20px' }}>
        <Box>
          <PdfTable data={data.result} reportPeriodType={reportPeriodType} />
        </Box>
      </Box>
    </Stack>
  )
}
export default TotalTrendReportPdf

import { Box } from '@chakra-ui/react'
import React from 'react'
import { IReportResult } from '../../../../api/trendReportPdf'
import TableDays from './TableDays'

type ReportPdfDaysProps = {
  data: IReportResult[]
}

const ReportPdfDays = (props: ReportPdfDaysProps) => {
  const { data } = props

  const numberOfDays = data.length

  let tablesData: Array<Array<IReportResult>> = []

  if (numberOfDays > 3) {
    const chunkSize = 3
    for (let i = 0; i < numberOfDays; i += chunkSize) {
      tablesData.push(data.slice(i, i + chunkSize))
    }
  } else {
    tablesData = [data]
  }

  return (
    <>
      {tablesData.map((result, index) => (
        <Box key={`table-${index}`} style={{ boxSizing: 'border-box', width: '100%' }}>
          <TableDays data={result} isLastTable={index === tablesData.length - 1} />
        </Box>
      ))}
    </>
  )
}

export default ReportPdfDays

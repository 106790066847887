import React, { useState } from 'react'
import {
    Box,
    Flex,
    Text,
    List,
    Collapse,
    Checkbox,
    Button,
    Menu,
    MenuButton,
    MenuList,
    Divider,
    Tooltip,
    PlacementWithLogical,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { UserDevices } from '../../../app/services/api/types'

interface TreeSelectProps {
    treeData: UserDevices[]
    selectedOptions: string[]
    setSelectedOptions: (value: string[]) => void
    header?: string
    isDisabled?: boolean
    required?: boolean
    tooltip?: {
        label: string
        hasArrow: boolean
        placement: PlacementWithLogical
    }
}

const TreeSelect: React.FC<TreeSelectProps> = ({
    treeData,
    selectedOptions,
    setSelectedOptions,
    header,
    isDisabled,
    tooltip,
    required
}) => {
    const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>({})

    const renderWithTooltip = (children: JSX.Element) => (
        <Tooltip
            w="full"
            label={tooltip!.label}
            placement={tooltip!.placement}
            hasArrow={tooltip!.hasArrow}
            shouldWrapChildren
        >
            {children}
        </Tooltip>
    )

    const MenuButtonComponent = (
        <MenuButton
            w="full"
            isDisabled={isDisabled}
            as={Button}
            rightIcon={<ChevronDownIcon />}
            bg="white"
            border="1px"
            height="48px"
            borderColor={required ? "red.500" : "border.strong"}
            textAlign="left"
            fontSize="18px"
            _disabled={{ color: 'gray.400' }}
            fontWeight="normal"
        >
            {header}
        </MenuButton>
    )


    const toggleOption = (key: string) => {
        if (selectedOptions.includes(key)) {
            setSelectedOptions(selectedOptions.filter((option) => option !== key))
        } else {
            setSelectedOptions([...selectedOptions, key])
        }
    }

    const toggleExpand = (key: string) => {
        setExpandedNodes((prev) => ({
            ...prev,
            [key]: !prev[key],
        }))
    }

    const isChecked = (key: string) => selectedOptions.includes(key);

    const renderTreeNodes = (nodes: UserDevices[], level = 0) => {
        return nodes?.map((node) => (
            <Box key={node.id} pl={level > 0 ? 4 : 0} borderLeft={level > 0 ? '1px solid #E2E8F0' : 'none'} ml={level > 0 ? 4 : 0}>
                <Flex
                    align="center"
                    justify="space-between"
                    py="2"
                    px="3"
                    borderRadius="md"
                    _hover={{ bg: 'blue.50' }}
                    cursor={node.children?.length ? 'pointer' : 'default'}
                    onClick={() => node.children?.length && toggleExpand(node.id)}
                >
                    <Flex align="center" gap="2">
                        {node.children?.length > 0 && (
                            <Text
                                fontSize="14px"
                                fontWeight="bold"
                                color="blue.500"
                                width="16px"
                                textAlign="center"
                            >
                                {expandedNodes[node.id] ? '-' : '+'}
                            </Text>
                        )}
                        <Checkbox
                            isChecked={isChecked(node.id.toString())}
                            onChange={() => toggleOption(node.id.toString())}
                            isDisabled={isDisabled}
                        >
                            {node.title}
                        </Checkbox>
                    </Flex>
                </Flex>
                {node.children?.length > 0 && (
                    <Collapse in={expandedNodes[node.id]} animateOpacity>
                        <Box pt="2">
                            {renderTreeNodes(node.children, level + 1)}
                        </Box>
                    </Collapse>
                )}
            </Box>
        ))
    }

    return (
        <Menu closeOnSelect={false}>
            {isDisabled && tooltip
                ? renderWithTooltip(MenuButtonComponent)
                : MenuButtonComponent}
            <MenuList width="600px" maxHeight="300px" overflowY="auto" p="2">
                {treeData?.length > 0 ? (
                    <Box p="2">
                        <Text fontWeight="bold" mb="3" color="gray.700">
                            {header || 'Options'}
                        </Text>
                        <Divider mb="3" />
                        <List>{renderTreeNodes(treeData)}</List>
                    </Box>
                ) : "No devices found for the current filters"}
            </MenuList>
        </Menu>
    )
}

export default TreeSelect
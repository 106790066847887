import { Flex, Spinner, Stack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { ILocationResult } from '../../api/locations'
import { IDetailedTrendReport, IReportTotalResult } from '../../api/trendReportPdf'
import { ReportPeriodType } from '../GenerateReport'
import { DetailedTrendReportPdf } from './PdfComponents/DetailedTrendReportPdf'
import TotalTrendReportPdf from './PdfComponents/TotalTrendReportPdf'

type Props = {
  data: IReportTotalResult | IDetailedTrendReport[]
  locations: ILocationResult[]
  fromDate: Date
  toDate: Date
  reportPeriodType: ReportPeriodType
  totalDetailed: number
  isDownloading: boolean
  isDataLoading: boolean
  energyType: number
}

const parseDate = (dateString: Date): string => {
  return dateString.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })
}

export const TrendReportPdf = ({
  data,
  locations,
  fromDate,
  toDate,
  reportPeriodType,
  totalDetailed,
  isDownloading,
  isDataLoading,
  energyType,
}: Props) => {
  const reportStartDate = useMemo(() => {
    return parseDate(fromDate)
  }, [data, reportPeriodType])

  const reportEndDate = useMemo(() => {
    return parseDate(toDate)
  }, [data, reportPeriodType])

  let reportEnergyType = ''

  switch (energyType) {
    case 1:
      reportEnergyType = 'Consumption energy'
      break
    case 2:
      reportEnergyType = 'Produced energy'
      break
    default:
      break
  }

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="left"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        {isDownloading || isDataLoading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : totalDetailed === 1 || totalDetailed === null ? (
          <TotalTrendReportPdf
            reportStartDate={reportStartDate}
            reportEndDate={reportEndDate}
            locations={locations}
            data={data as IReportTotalResult}
            reportPeriodType={reportPeriodType}
            reportEnergyType={reportEnergyType}
          />
        ) : (
          <DetailedTrendReportPdf
            reportStartDate={reportStartDate}
            reportEndDate={reportEndDate}
            locations={locations}
            data={data as IDetailedTrendReport[]}
            reportPeriodType={reportPeriodType}
            reportEnergyType={reportEnergyType}
          />
        )}
      </Flex>
    </Stack>
  )
}

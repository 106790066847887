import { Box, Stack } from '@chakra-ui/react'
import React from 'react'
import { calculateStepSize } from '../../../../../utils/helpers'
import { ILocationResult } from '../../../api/locations'
import { IDetailedTrendReport } from '../../../api/trendReportPdf'
import { ReportPeriodType } from '../../GenerateReport'
import { GenerateTrendChart } from './GenerateTrendChart/GenerateTrendChart'
import { NewPageHeader } from './NewPageHeader'
import { PdfTable } from './PdfTable/PdfTable'
import { TrendReportHeader } from './TrendReportHeader'

type Props = {
  reportStartDate: string
  reportEndDate: string
  locations: ILocationResult[]
  data: IDetailedTrendReport[]
  reportPeriodType: ReportPeriodType
  reportEnergyType: string
}

export const DetailedTrendReportPdf = ({
  reportStartDate,
  reportEndDate,
  locations,
  data,
  reportPeriodType,
  reportEnergyType,
}: Props) => {
  
  return (
    <Stack width={'100%'} alignItems="center">
      <Box height={'1100px'} width={'100%'}>
        <TrendReportHeader
          locations={locations}
          startDate={reportStartDate}
          endDate={reportEndDate}
          classification='Trend report'
        />
      </Box>
      {data &&
        data.map((x, index) => {
          return (
            <Box key={`trend-body-${index}`}>
              <Box>
                <Box className="trendChart">
                  <NewPageHeader
                    reportType={x.name}
                    period={`${reportStartDate} - ${reportEndDate}`}
                  />
                  <GenerateTrendChart
                    data={x.data}
                    reportPeriodType={reportPeriodType}
                    reportEnergyType={reportEnergyType}
                  />
                </Box>
              </Box>
              <Box style={{ marginTop: '20px', marginBottom: '30px' }}>
                <Box>
                  <PdfTable data={x.data} reportPeriodType={reportPeriodType} />
                </Box>
              </Box>
            </Box>
          )
        })}
    </Stack>
  )
}

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
    Flex,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Box,
    IconButton
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export default function CsvPreviewTable({ csvData, isLoading }) {
    const rowsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState(null)

    useEffect(() => {
        if (csvData) {
            setData(csvData)
        }
    }, [csvData])

    if (!data || !Array.isArray(data) || data.length === 0 || isLoading) {
        return (
            <Flex direction="column" justifyContent="center" alignItems="center" width="100%" height="500px">
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
            </Flex>
        );
    }

    const columnNames = Object.keys(data[0]);
    const totalRows = data.length;
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    const handlePrevious = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNext = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const startRow = (currentPage - 1) * rowsPerPage;
    const currentData = data.slice(startRow, startRow + rowsPerPage);

    return (
        <Flex direction="column" alignItems="center" width="100%">
            <TableContainer width="100%">
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            {columnNames.map((key) => (
                                <Th key={key}>{key}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentData.map((row, rowIndex) => (
                            <Tr key={startRow + rowIndex}>
                                {columnNames.map((key) => (
                                    <Td key={key}>{row[key]}</Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Flex margin="10px 0" alignItems="center" justifyContent="center" gap={5} width="100%">
                <IconButton
                    onClick={handlePrevious}
                    isDisabled={currentPage === 1}
                    colorScheme="blue"
                    aria-label="Previous page"
                    icon={<ChevronLeftIcon />}
                />
                <Box>
                    Page <strong>{currentPage}</strong> of <strong>{totalPages}</strong>
                </Box>
                <IconButton
                    onClick={handleNext}
                    isDisabled={currentPage === totalPages}
                    colorScheme="blue"
                    aria-label="Next page"
                    icon={<ChevronRightIcon />}
                />
            </Flex>
        </Flex >
    );
}

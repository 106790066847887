import { Box, Text } from '@chakra-ui/react'
import React from "react"
import { IBillingLocationReport } from '../../../api/types'
import { NewPageHeader } from "../PdfComponents/NewPageHeader"
import { BillingReportTable } from "./BillingReportTable"

type Props = {
  data: IBillingLocationReport[]
  reportStartDate: string
  reportEndDate: string
  classification: number
}
export const BillingReportBody = ({ data, reportStartDate, reportEndDate, classification }: Props) => {
  return (
    <Box width="100%" mt={20} p={5}>
      {
        data.map((x, index) => {
          return (
            <Box key={`trend-body-${index}`}>
              <Box>
                <Box className="trendChart" width="100%">
                  <NewPageHeader
                    reportType={x.title}
                    period={`${reportStartDate} - ${reportEndDate}`}
                  />
                </Box>
              </Box>
              <Box style={{ margin: '20px 0' }}>
                <Text color="#3893ed" mt={5}>
                  {x.error_message}
                </Text>
              </Box>
              <Box style={{ margin: '20px 0' }}>
                <Box>
                  <BillingReportTable classification={classification} location={x} />
                </Box>
              </Box>
            </Box>
          )
        })
      }
    </Box>)
}
export const enum Periods {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export interface Title {
  title: string
}

export interface Period {
  from: string
  to: string
  period?: Periods
  last_hour?: string
}

export interface CurrentDataRequest {
  deviceIds: number[]
  fields: string[]
}
export interface CurrentLocationsDataRequest {
  locationIds: number[]
}

export interface DashBoardRequest {
  from?: string
  to?: string
  location?: string | number
  last_hour?: string
  period?: Periods
  testUser?: string
}

export declare module Location {
  export interface SubLocation {
    id: number
    title: string
    description: null
    image: null
    price: null | string
    price_unit: PriceUnit
    devices_count: number
    has_main_device: boolean
    children: any[]
    parent_id: number
    emission_zone: null
    sublocations_count: number
  }

  export interface Data {
    id: number
    title: string
    description: null | string
    image: null | string
    price: number | null
    price_unit: PriceUnit
    devices_count: number
    has_main_device: boolean
    children: SubLocation[]
    parent_id: number | null
    emission_zone: number | null
    sublocations_count: number
    total_consumption: number
    min_consumption: number
    max_consumption: number
    min_temperature: null
    max_temperature: null
    humidity: null
    current_usage: number | null
    carbon_intensity: number | null
  }

  export enum PriceUnit {
    KW = 'kW',
  }

  export interface Response {
    count: number
    next?: any
    previous?: any
    results: Data[]
  }
}

export enum PriceUnit {
  KW = 'kW',
}

export interface DashboardOverviewLocation {
  current_usage: number | null
  description: null | string
  id: number
  image: null | string
  price_unit: PriceUnit
  sublocations_count: number
  title: string
  energy_data: EnergyData
  parent_id: number | null
}
export interface DeviceRowDevice {
  id: number
  title: string
  current_usage: number | null
  total_consumption?: number
  total_generated_power?: number
  category?: string
}

export interface DashboardDevice {
  id: number
  is_hidden: boolean
  is_main: boolean
  is_virtual: boolean
  current_usage: number | null
  category: string | undefined
  total_generated_power: number | undefined
  title: string
  total_consumption: number
}

interface EnergyData {
  total_consumption: number
  total_generated_power: number
  consumption_cost: number
  generated_income: number
}

export interface DashboardLocation {
  carbon_intensity: number | null
  carbon_footprint: number | null
  current_usage: number | null
  description: null | string
  image: null | string
  id: number
  title: string
  price: number | null
  price_unit: PriceUnit
  energy_data: EnergyData

  devices: DashboardDevice[]
  temp_and_humidity: LocationChart.Data[]
  main_device_average_energy: LocationChart.Data[]
  has_temp_sensor: boolean

  devices_count: number
  has_main_device: boolean
  // children: SubLocation[]
  parent_id: number | null
  emission_zone: number | null
  sublocations_count: number
  min_consumption: number
  max_consumption: number
  min_temperature: null
  max_temperature: null
  humidity: null
}

export interface DashboardOverview {
  count: number
  next?: any
  previous?: any
  results: DashboardOverviewLocation[]
}

export declare module TopFiveDevice {
  export interface Data {
    id: number
    title: string
    description: null | string
    parent_id: null
    device_type: DeviceType | null
    children_count: number
    is_main: boolean
    location: Location | null
    on_dashboard: boolean
    total_consumption: number
    power_factor: number
    min_consumption: number
    max_consumption: number
    min_temperature: null
    max_temperature: null
    humidity: null
    current_usage: null
    carbon_intensity: null
    price: null
    price_unit: string
  }

  export interface DeviceType {
    id: number
    name: string
    title: string
    image: string
    device_type_parameters: DeviceTypeParameter[]
  }

  export interface DeviceTypeParameter {
    id: number
    name: string
  }

  export interface Location {
    id: number
    title: string
  }
}

export declare module ChartDevice {
  export interface Location {
    id: number
    title: string
  }

  export interface Data {
    id: number
    title: string
    location: Location
    consumption: number
    current_usage: number
    unit: string
    is_main: boolean
  }
}

export declare module DeviceReport {
  interface Phase {
    consumption: number
    power_factor?: any
    min_voltage: number
    max_voltage: number
    current?: any
    resistance?: any
  }

  interface Extras {
    phase_count: number
    params: any[]
    total_consumption: number
    total_generated_power: number
  }

  interface Data {
    time: string
    consumption: number
    power_factor?: any
    min_voltage: number
    max_voltage: number
    phases_count: number
    current: number
    resistance?: any
    carbon_intensity: number
    fossil_fuel: number
    phases: Phase[]
  }

  interface Request {
    from?: string
    to?: string
    period?: Periods
    device?: string
  }

  interface Response {
    results: Data[]
    extras: Extras
  }
}

export declare module LocationChart {
  interface Data {
    time: string
    hour: string
    day: string
    month: string
    avg_consumption: number
    avg_generated_power: number
    power_factor?: any
    min_voltage: number
    max_voltage: number
    phases_count: number
    current: number
    resistance?: any
    carbon_intensity: number
    fossil_fuel: number
    avg_humidity?: number
    avg_temperature?: number
  }

  interface Request {
    from?: string
    to?: string
    period?: Periods
    location: string
  }
}

//Defects
export declare module Defects {
  export interface Location {
    id: number
    title: string
    parent?: any
  }

  export interface Device {
    id: number
    title: string
    location: Location
  }

  export interface Defect {
    id: number
    location: number
    device: Device
    type: number
    start_time: string
    end_time: string
  }

  export interface Response {
    count: number
    next: string
    previous?: any
    results: Defect[]
  }
}

export declare module DeviceData {
  interface DeviceType {
    name: string
    title: string
    image?: any
  }

  interface Data {
    id: number
    title: string
    category: string
    description?: any
    parent_id: number
    children_count: number
    is_main: boolean
    location_id: number
    on_dashboard: boolean
    serial_number: string
    device_type: DeviceType
    device_type_relations: any[]
  }
}

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React, { useMemo, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGetAnalysisConsumptionBreakdownQuery } from '../api/analysisApi'
import { SelectableItem } from '../api/types'
import DevicesContainer from '../../dashboard/components/DevicesContainer'

interface Props {
  selectedHours: SelectableItem[]
  onClose: () => void
  setIsLoading: (isLoading: boolean) => void
  isOpen: boolean
  device: string
}

const DevicesBreakdown = ({ selectedHours, onClose, setIsLoading, isOpen, device }: Props) => {
  const [searchParams] = useSearchParams()
  const locationId = parseInt(searchParams.get('location')!)

  const requestData = useMemo(() => {
    const periods = selectedHours.reduce<{ [index: string]: string[][] }>(
      (acc, { day, hour }) => {
        let shouldAddNew = true
        let currentDay = acc[day]
        if (!currentDay) {
          currentDay = []
          acc[day] = currentDay
        }

        const startHour = hour
        const endHour = hour
        const startHourStr = `${hour}:00`
        const endHourStr = `${hour}:00`
        if (currentDay.length) {
          currentDay.map((hours) => {
            const currentDayStartHour = parseInt(hours[0].split(':')[0])
            const currentDayEndHour = parseInt(hours[1].split(':')[0])
            if (currentDayStartHour === startHour - 1) {
              if (currentDayEndHour < endHour) {
                hours[1] = `${endHour}:00`
                shouldAddNew = false
              }
            }
            if (currentDayEndHour === startHour - 1) {
              hours[1] = `${endHour}:00`
              shouldAddNew = false
            }

            return hours
          })

          if (shouldAddNew) {
            currentDay.push([startHourStr, endHourStr])
          }
          return acc
        }
        currentDay.push([startHourStr, endHourStr])
        return acc
      },
      {},
    )

    const totalConsumption = selectedHours.reduce(
      (sum, { consumption }) => sum + consumption,
      0,
    )

    return {
      location_id: locationId,
      period: periods,
      device_id: device,
      total_consumption: totalConsumption,
    }
  }, [selectedHours, locationId, device])

  const { isLoading, currentData } =
    useGetAnalysisConsumptionBreakdownQuery(requestData)

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading, setIsLoading])

  const consumers = useMemo(() => {
    return currentData?.filter((device) => device.children.length > 0 && device.total_consumption > 0)
  }, [currentData])

  const generators = useMemo(() => {
    return currentData?.filter(
      (device) => device.children.length > 0 && device.total_generated_power > 0,
    )
  }, [currentData])

  return (
    <>
      {!isLoading ? (
        <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent
            maxW={840}
            height='auto'
            maxH={800}
          >
            <ModalHeader>Devices Breakdown</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {consumers &&
                consumers.map((device) => (
                  <DevicesContainer
                    key={device.id}
                    title="Consumers"
                    devices={device}
                    totalConsumption={device.total_consumption}
                    type="consumers"
                    locationId={locationId}
                  />
                ))
              }
              {generators &&
                generators.map((device) => (
                  <DevicesContainer
                    key={device.id}
                    title="Generators"
                    devices={device}
                    totalConsumption={device.total_generated_power}
                    type="generator"
                    locationId={locationId}
                  />
                ))
              }
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}

export default DevicesBreakdown

import { CheckCircleIcon, SmallAddIcon } from '@chakra-ui/icons'
import { Center } from '@chakra-ui/react'
import { createSelectable } from 'react-selectable-fast'
import { Analysis } from '../../api/types'
import './Cell.css'
import { Tooltip } from '@chakra-ui/react'

import { KILOWATT_HOUR } from '../../../../utils/consts'
import {
  consumptionColorsArray,
  HEAT_MAP_KEY_CONSUMPTION,
  photovoltaicsColorsArray,
} from '../../utils'

export const Cell = createSelectable<{
  item: Analysis.DayResultUIModel
  heatMapKey: string
}>(({ selectableRef, isSelected, isSelecting, item, heatMapKey }) => {
  const colorsArray =
    heatMapKey === HEAT_MAP_KEY_CONSUMPTION
      ? consumptionColorsArray
      : photovoltaicsColorsArray
  const getColor = (consum: number) => {
    if (consum >= (item.consumptionRates?.color1 || 1)) {
      return colorsArray[0].color
    }
    if (consum >= (item.consumptionRates?.color2 || 1)) {
      return colorsArray[1].color
    }
    if (consum >= (item.consumptionRates?.color3 || 1)) {
      return colorsArray[2].color
    }
    if (consum >= (item.consumptionRates?.color4 || 1)) {
      return colorsArray[3].color
    }
    if (consum >= (item.consumptionRates?.color5 || 1)) {
      return colorsArray[4].color
    }
    if (consum >= (item.consumptionRates?.color6 || 1)) {
      return colorsArray[5].color
    }
    if (consum >= (item.consumptionRates?.color7 || 1)) {
      return colorsArray[6].color
    }
    if (consum >= (item.consumptionRates?.color8 || 1)) {
      return colorsArray[7].color
    }
    if (consum > 0 && consum < (item.consumptionRates?.color8 || 1)) {
      return colorsArray[8].color
    }
    // if (consum >= (item.consumptionRates?.color10 || 1)) {
    //   return colorsArray[9].color
    // }
    // if (consum >= (item.consumptionRates?.color11 || 1)) {
    //   return colorsArray[10].color
    // }
    // if (consum >= (item.consumptionRates?.color12 || 1)) {
    //   return colorsArray[11].color
    // }
    // if (consum >= (item.consumptionRates?.color13 || 1)) {
    //   return colorsArray[12].color
    // }
    // if (consum >= (item.consumptionRates?.color14 || 1)) {
    //   return colorsArray[13].color
    // }
    // if (consum >= (item.consumptionRates?.color15 || 1)) {
    //   return colorsArray[14].color
    // }
    // if (consum >= (item.consumptionRates?.color16 || 1)) {
    //   return colorsArray[15].color
    // }
    // if (consum >= (item.consumptionRates?.color17 || 1)) {
    //   return colorsArray[16].color
    // }
    // if (consum >= (item.consumptionRates?.color18 || 1)) {
    //   return colorsArray[17].color
    // }
    // if (consum > 0 && consum < (item.consumptionRates?.color19 || 1) ) {
    //   return colorsArray[18].color
    // }
    if (consum === 0) {
      return colorsArray[9].color
    }
  }

  return (
    <Tooltip
      label={`${item.day} / ${item.hour}h / ${item.consumption !== null ? `${+parseFloat(item.consumption?.toFixed(3))} ${KILOWATT_HOUR}` : 'N/A'}`}
    >
      <Center
        as="div"
        w={12}
        h={6}
        m="0"
        border={'0.5px solid #eee'}
        position="relative"
        ref={selectableRef}
        role="group"
        // aria-selected={isSelected}
        className={
          isSelected ? 'selectedItem' : isSelecting ? 'selectingItem' : 'selectableItem'
        }
        _hover={{
          boxShadow: 'inset 0px 0px 10px #7767677e',
        }}
        bgColor={getColor(item.consumption)}
        boxShadow={isSelected ? 'inset 0px 0px 10px #7767677e' : 'none'}
      >
        {item.consumption !== null && (
          isSelected ? (
            <CheckCircleIcon color={'white'} />
          ) : isSelecting ? (
            <SmallAddIcon color={item.consumption < 1.5 ? 'gray.400' : 'white'} />
          ) : (
            <SmallAddIcon
              display="none"
              color={item.consumption < 1.5 ? 'gray.400' : 'white'}
              _groupHover={{ display: 'block' }}
            />
          )
        )}

      </Center>
    </Tooltip>
  )
})

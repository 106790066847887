import { Box, Divider, Grid, GridItem, Text } from '@chakra-ui/react'
import React from 'react'
import { KILOWATT_HOUR } from '../../../../../utils/consts'
import { addCommasToNumber } from '../../../../../utils/helpers'
import { IBillingLocationReport, IBillingReportData } from '../../../api/types'
import { BillingTableHeaderText } from './BillingTableHeaderText'

type Props = {
  classification: number
  location: IBillingLocationReport
}

export const BillingReportTable = ({ classification, location }: Props) => {
  let energyType = classification === 1 ? 'Consumption' : 'Generated'

  const renderDeviceRow = (device: IBillingReportData, level: number = 0) => {
    const hasChildren = device.children && device.children.length > 0

    return (
      <>
        <Grid
          templateColumns="repeat(15, 1fr)"
          fontSize="sm"
          bg="white"
          key={`device-row-${device.id}`}
        >
          <GridItem colSpan={5} alignContent="center">
            <Text p={2}>
              {'-'.repeat(level)} {device.title}
            </Text>
          </GridItem>
          <GridItem colSpan={2} borderLeft="1px" alignContent="center">
            <Text p={2} whiteSpace="normal" wordBreak="break-word">
              {device.type}
            </Text>
          </GridItem>
          <GridItem colSpan={2} borderLeft="1px" alignContent="center">
            <Text p={2} whiteSpace="normal" wordBreak="break-word">
              {device.group || '-'}
            </Text>
          </GridItem>
          {energyType === 'Consumption' ? (
            <>
              <GridItem colSpan={2} borderLeft="1px" alignContent="center">
                <Text p={2}>
                  {addCommasToNumber(
                    device.consumption_cost / device.total_consumption || 0,
                  )}
                </Text>
              </GridItem>
              <GridItem colSpan={2} borderLeft="1px" alignContent="center">
                <Text p={2}>{addCommasToNumber(device.total_consumption || 0)}</Text>
              </GridItem>
              <GridItem colSpan={2} borderLeft="1px" alignContent="center">
                <Text p={2}>{addCommasToNumber(device.consumption_cost || 0)}</Text>
              </GridItem>
            </>
          ) : (
            <>
              <GridItem colSpan={2} borderLeft="1px" alignContent="center">
                <Text p={2}>
                  {addCommasToNumber(
                    device.generated_income / device.total_generated_power || 0,
                  )}
                </Text>
              </GridItem>
              <GridItem colSpan={2} borderLeft="1px" alignContent="center">
                <Text p={2}>{addCommasToNumber(device.total_generated_power || 0)}</Text>
              </GridItem>
              <GridItem colSpan={2} borderLeft="1px" alignContent="center">
                <Text p={2}>{addCommasToNumber(device.generated_income || 0)}</Text>
              </GridItem>
            </>
          )}
        </Grid>
        <Divider borderColor="dark.400" />

        {hasChildren && (
          <>
            {/* Divider between parent and children */}
            {device.children.map((child) => renderDeviceRow(child, level + 1))}
          </>
        )}
      </>
    )
  }

  return (
    <Box overflowX="auto" border="1px" borderColor="black.300" borderRadius="md">
      <Grid templateColumns="repeat(1, 1fr)" bg="gray.50">
        <Grid templateColumns="repeat(15, 1fr)">
          <GridItem colSpan={5}>
            <BillingTableHeaderText text="Device" />
          </GridItem>
          <GridItem borderLeft="1px" colSpan={2}>
            <BillingTableHeaderText text="Type" />
          </GridItem>
          <GridItem borderLeft="1px" colSpan={2}>
            <BillingTableHeaderText text="Group" />
          </GridItem>
          <GridItem borderLeft="1px" colSpan={2}>
            <BillingTableHeaderText text={`Price (BGN/${KILOWATT_HOUR})`} />
          </GridItem>
          <GridItem borderLeft="1px" colSpan={2}>
            <BillingTableHeaderText text={`${energyType} (${KILOWATT_HOUR})`} />
          </GridItem>
          <GridItem borderLeft="1px" colSpan={2}>
            <BillingTableHeaderText
              text={energyType === 'Consumption' ? 'Cost (BGN)' : 'Income (BGN)'}
            />
          </GridItem>
        </Grid>

        {location.data.map((device) => renderDeviceRow(device))}

        <Grid templateColumns="repeat(15, 1fr)" fontSize="sm" fontWeight="bold">
          <GridItem colSpan={11} textAlign="right" p={2}>
            Total:
          </GridItem>
          {energyType === 'Consumption' ? (
            <>
              <GridItem colSpan={2} textAlign="center" borderLeft="1px">
                <Text p={2}>{`${addCommasToNumber(
                  location.total_consumption,
                )} ${KILOWATT_HOUR}`}</Text>
              </GridItem>
              <GridItem colSpan={2} textAlign="center" borderLeft="1px">
                <Text p={2}>{addCommasToNumber(location.consumption_cost)} BGN</Text>
              </GridItem>
            </>
          ) : (
            <>
              <GridItem colSpan={2} textAlign="center" borderLeft="1px">
                <Text p={2}>{`${addCommasToNumber(
                  location.total_generated_power,
                )} ${KILOWATT_HOUR}`}</Text>
              </GridItem>
              <GridItem colSpan={2} textAlign="center" borderLeft="1px">
                <Text p={2}>{addCommasToNumber(location.generated_income)} BGN</Text>
              </GridItem>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

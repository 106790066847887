import { IReportResult } from '../../../../api/trendReportPdf'
import { Stack, Text } from '@chakra-ui/react'

type TableDaysProps = {
  data: IReportResult[]
  isLastTable?: boolean
}

const TableDays = (props: TableDaysProps) => {
  const { data, isLastTable = true } = props

  return (
    <>
      <Stack width="100%" border="1px solid black" className="down">
        <Stack
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid black"
          padding={1}
        >
          <Stack width="50px" alignItems="flex-end" justifyContent="center" pl={1}>
            <Text className="tableRows" fontFamily="Inter" fontWeight={700}>
              Hour
            </Text>
          </Stack>
          {data.map((item, idx) => (
            <Stack
              key={`date-header-${idx}`}
              mt="0 !important"
              style={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                className="tableRows"
                fontFamily="Inter"
                fontWeight={700}
                lineHeight="100%"
                fontSize="1rem"
                mt="0 !important"
              >
                {item.date}
              </Text>
            </Stack>
          ))}
        </Stack>

        <Stack sx={{ margin: 0 }} height="100%" mt="0 !important">
          {Array.from({ length: 24 }).map((_, hourIndex) => (
            <Stack
              flexDirection="row"
              mt="0 !important"
              key={`hour-${hourIndex}`}
              borderBottom={hourIndex < 23 ? '1px solid black' : 'none'}
            >
              <Stack
                width="170px"
                alignItems="center"
                justifyContent="center"
                fontFamily="Inter"
                px={2}
                mt="0 !important"
              >
                <Text className="tableRows">{`${hourIndex.toString()}:00`}</Text>
              </Stack>

              {data.map((item, i) => {
                const hourData = item.period.find((p) => parseInt(p.period) === hourIndex)
                return (
                  <Stack
                    key={`hour-${hourIndex}-date-${i}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderLeft: '1px solid black',
                      fontFamily: 'Inter',
                    }}
                  >
                    <Text className="tableRows">
                      {hourData ? `${hourData.value} kWh` : 'N/A'}
                    </Text>
                  </Stack>
                )
              })}
            </Stack>
          ))}
        </Stack>
      </Stack>

    </>
  )
}

export default TableDays

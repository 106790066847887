import React, { useCallback } from 'react'
import {
  Button,
  HStack,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react'
import { DashboardOverviewLocation } from '../types'
import { DASHBOARDPARAMS } from '../consts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQueryParams } from '../hooks/useQueryParams'
import { mutatePriceUnit, addCommasToNumber } from '../../../utils/helpers'
import { TrendDownIcon } from '../../../assets/icons/TrendDown'
import { TrendUpIcon } from '../../../assets/icons/TrendUp'
import { KILOWATT, KILOWATT_HOUR } from '../../../utils/consts'
import Flippable from '../components/Flippable'

type Props = {
  data: DashboardOverviewLocation[]
  isLoading: boolean
  currentLocationsData: any
}

const LOCATIONHEADERS = [
  'Location name',
  'Current usage',
  'Produced energy',
  'Total consumption',
]

export const LocationTable: React.FC<Props> = ({
  data,
  isLoading,
  currentLocationsData,
}) => {
  const navigate = useNavigate()
  const [queryParams] = useQueryParams(DASHBOARDPARAMS)
  const hiddenOnPhone = useBreakpointValue({ base: true, md: false })

  const hiddenOnTablet = useBreakpointValue({
    base: false,
    md: false,
    lg: false,
    xl: false,
  })

  const generateDetailsButton = useCallback(
    (id: number, idx: number) => (
      <Button
        key={idx}
        size="sm"
        colorScheme="button.primary"
        onClick={() =>
          navigate(
            `/dashboard/location/${id.toString()}?${new URLSearchParams(
              queryParams,
            ).toString()}`,
          )
        }
      >
        Details
      </Button>
    ),
    [queryParams, navigate],
  )
  return (
    <Table size="lg">
      <Thead>
        <Tr>
          {LOCATIONHEADERS.map((item, idx) => (
            <Th
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight="bold"
              color="text.intermediate"
              textTransform="initial"
              key={idx}
              hidden={hiddenOnTablet && idx === 1}
            >
              {item}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {isLoading
          ? Array.from(Array(5)).map((item, idx) => (
              <Tr key={idx}>
                <Td>
                  <Skeleton w="full" h="32px" />
                </Td>
                <Td hidden={hiddenOnTablet}>
                  <Skeleton w="full" h="32px" />
                </Td>
                <Td>
                  <Skeleton w="full" h="32px" />
                </Td>
                <Td>
                  <Skeleton w="full" h="32px" />
                </Td>
              </Tr>
            ))
          : data
              .map(
                (
                  { title, id, price_unit, energy_data },
                  idx,
                ) => {
                  return (
                    <Tr key={idx}>
                      <Td
                        key={`LocationTd${idx}`}
                        fontWeight="bold"
                        fontSize={{ base: 'md', md: 'lg' }}
                        color="text.dark"
                      >
                        <Text
                          key={`Location${idx}`}
                          cursor="pointer"
                          onClick={() =>
                            navigate(
                              `/dashboard/location/${id.toString()}?${new URLSearchParams(
                                queryParams,
                              ).toString()}`,
                            )
                          }
                        >
                          {title}
                        </Text>
                        <Text
                          key={`Sublocation${idx}`}
                          fontSize="xs"
                          fontWeight="normal"
                          color="text.light"
                          hidden={hiddenOnPhone}
                        >
                        </Text>
                        {hiddenOnPhone && generateDetailsButton(id, idx)}
                      </Td>
                      <Td
                        hidden={hiddenOnTablet}
                        fontSize="md"
                        color="text.dark"
                        key={`CurrentUsageTd${idx}`}
                      >
                        <Flippable
                          value={addCommasToNumber(
                            Math.round(currentLocationsData[id]?.consumption || 0),
                          )}
                          unit={KILOWATT_HOUR}
                        />
                      </Td>
                      <Td key={`ProducedEnergy${idx}`}>
                        <HStack key={idx}>
                          {energy_data.total_generated_power > 0 ? (
                            <TrendUpIcon color="#21D17F" mt="2" />
                          ) : null}
                          <Text key={idx} as="span" fontSize="md" color="text.dark">
                            {energy_data.total_generated_power > 0 ? (
                              <Flippable
                                value={addCommasToNumber(
                                  Math.round(energy_data.total_generated_power),
                                )}
                                unit={KILOWATT_HOUR}
                              />
                            ) : (
                              '-'
                            )}
                          </Text>
                        </HStack>
                      </Td>
                      <Td key={`TotalConsumptionTd${idx}`}>
                        <HStack key={idx}>
                          {energy_data.total_consumption ? (
                            <TrendDownIcon color="#EB6F21" />
                          ) : null}

                          <Text key={idx} as="span" fontSize="md" color="text.dark">
                            {energy_data.total_consumption
                              ? `${addCommasToNumber(
                                  energy_data.total_consumption,
                                )} ${mutatePriceUnit(price_unit)}`
                              : '-'}
                          </Text>
                        </HStack>
                      </Td>
                      <Td key={`DetailsTd${idx}`} textAlign="end" hidden={hiddenOnPhone}>
                        {generateDetailsButton(id, idx)}
                      </Td>
                    </Tr>
                  )
                },
              )}
      </Tbody>
    </Table>
  )
}

import { Text, Box, Stack } from '@chakra-ui/react'
import { ILocationResult } from '../../../api/locations'

import colors from '../../../../../theme/colors'
import logoSvg from '../../../../../assets/logo.svg'
import fileIcon from '../../../../../assets/file_text.png'
import checkIcon from '../../../../../assets/checkIcon.png'

type TrendReportHeaderProps = {
  locations: ILocationResult[]
  startDate: string
  endDate: string
  classification: string
}

export const TrendReportHeader = (props: TrendReportHeaderProps) => {
  const { locations, startDate, endDate, classification } = props

  return (
    <Box
      bgGradient="linear(to-b, #D4EBF2, #FBFDFE)"
      height="100%"
      width={'100%'}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
      textAlign="center"
      pt={40}
    >
      {/* Icon */}
      <Box mb={10}>
        <img width={86} height={86} src={fileIcon} alt="icon" />
      </Box>
      {/* Date Range Title */}
      <Stack
        className="locationName"
        height="fit-content"
        borderBottom={`3px solid ${colors.blue[100]}`}
      >
        <Text
          className="locationName"
          fontSize="3rem"
          fontWeight="bold"
          fontFamily={'Inter'}
          color="blue.900"
          m={0}
          mt={5}
          p={0}
        >
          {`${startDate} - ${endDate}`}
        </Text>
      </Stack>
      {/* Subtitle */}
      <Text fontSize="2rem" color="gray.600" fontFamily={'Roboto'} mt={5}>
        {classification}
      </Text>

      <Stack
        direction="row"
        gap={3}
        mt={80}
        wrap="wrap"
        width="90%"
        justifyContent="center"
      >
        {locations.map((location) => (
          <Stack
            className="locationName"
            key={location.id}
            border={`1px solid ${colors.blue[100]}`}
            borderRadius={'full'}
            pl={5}
            pr={6}
            py={1}
            sx={{ bgcolor: 'red' }}
            flexDirection={'row'}
            m={0}
          >
            <img
              src={checkIcon}
              alt="icon"
              style={{ width: 28, height: 28, alignSelf: 'center' }}
            />
            <Text
              className="locationName"
              fontWeight={'bold'}
              fontSize={'1.5rem'}
              color={colors.blue[100]}
              sx={{ textAlign: 'top', m: '0 !important' }}
            >
              {location.title}
            </Text>
          </Stack>
        ))}
      </Stack>
      <Stack mt={40}>
        <img src={logoSvg} alt="logo" />
      </Stack>
    </Box>
  )
}

import { Stack, Text } from '@chakra-ui/react'
import colors from '../../../../../theme/colors'

type Props = {
  reportType: string
  period: string
}
export const NewPageHeader = ({ reportType, period }: Props) => {
  return (
    <Stack
      className="fromToDate"
      direction="row"
      justify="space-between"
      mb="24px"
      padding={2}
      paddingBottom={3}
      borderRadius={'0.25em'}
      boxSizing="border-box"
      backgroundColor={colors.blue[100]}
    >
      <Stack direction="row" w="full" className="fromToDate">
        <Text color="white" fontSize="24px" fontWeight={700} lineHeight="30px"  className="up moveRight">
          {reportType}
        </Text>
      </Stack>
      <Stack direction="row" justify={'flex-end'} w="full" className="fromToDate">
        <Stack align="center" direction="row" spacing={4}>
          <Text color={'white'} whiteSpace="nowrap" className="up moveLeft">
            {period}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}

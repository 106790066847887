import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  Flex,
} from '@chakra-ui/react'
import html2pdf from 'html2pdf.js'
import { ILocationResult } from '../../api/locations'
import { IDetailedTrendReport, IReportResult } from '../../api/trendReportPdf'
import { ReportPeriodType } from '../GenerateReport'
import React, { useState } from 'react'
import { EnergyConsumptionReportPdf } from './EnergyConsumptionReportPdf'
import SendEmail from './SendEmail'
import { toast } from 'react-toastify'
import RecurringReports from './RecurringReports'
import BookmarkReport from './BookmarkReport'
import { TrendReportPdf } from './TrendReportPdf'
import { EnergyConsumptionReport, IBillingReport } from '../../api/types'
import { scrollBarStyle } from '../../../../utils/helpers'
import { ReportType } from '../../../../utils/consts'
import CsvPreviewTable from './CsvPreviewTable'
import { BillingReportPdf } from './BillingReportPdf'
import { useGetReportAsPDFMutation } from '../../../../app/services/api/api'

type PreviewDocumentDialogProps = {
  locations: ILocationResult[]
  startDate: any
  endDate: any
  reportType: number
  reportName: string
  pdfReportData: IReportResult[] | IDetailedTrendReport[]
  energyConsumptionReport: EnergyConsumptionReport
  reportPeriodType: ReportPeriodType
  reportData: any
  groupId: any[]
  deviceIds: any[]
  ibexIndex: number
  carbonIntensity: number
  deviceTypes: string[]
  energyType: number
  measurementTypes: string[]
  totalDetailed: number
  isOpen: boolean
  isTrendReportLoading: boolean
  savedAs: ReportType
  isCsvLoading: boolean
  billingReportData: IBillingReport[]
  onClose: () => void
  handleSendCsv: () => void
}

const PreviewDocumentDialog = (props: PreviewDocumentDialogProps) => {
  const {
    startDate,
    endDate,
    locations,
    reportType,
    reportName,
    pdfReportData,
    reportPeriodType,
    energyConsumptionReport,
    reportData,
    groupId,
    deviceIds,
    ibexIndex,
    carbonIntensity,
    isOpen,
    onClose,
    handleSendCsv,
    deviceTypes,
    energyType,
    measurementTypes,
    totalDetailed,
    isTrendReportLoading,
    savedAs,
    isCsvLoading,
    billingReportData,
  } = props

  const [isDownloading, setIsDownloading] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)

  const [getReportAsPDF, { isLoading: isPdfLoading }] = useGetReportAsPDFMutation()
  
  const handleClick = async () => {
    const component = document.getElementById('pdf-container')
    if (!component) return

    const options = {
      margin: 0,
      filename: `${reportName}.pdf`,
      pagebreak: {
        mode: 'avoid-all',
        before: '#reportBody',
      },
    }

    const applyStyles = (selector: string, styles: Record<string, string>) => {
      const elements = component.querySelectorAll(selector) as unknown as HTMLElement[]
      elements.forEach((el) => {
        Object.assign(el.style, styles)
      })
      return elements
    }

    // Style adjustments before generating the PDF
    const elementAdjustments = [
      { selector: '.locationName', styles: { transform: 'translate(0%, -35%)' } },
      { selector: '.cardTitle', styles: { transform: 'translate(0%, -40%)' } },
      { selector: '.arrowIconClass', styles: { transform: 'translate(0%, 15%)' } },
      { selector: '.groupName', styles: { transform: 'translate(0%, -35%)' } },
      { selector: '.groupPercentage', styles: { transform: 'translate(0%, -35%)' } },
      { selector: '.dot', styles: { marginTop: '15px' } },
      { selector: '.donutChart', styles: { transform: 'translate(0%, -25%)' } },
      { selector: '.fromToDate', styles: { transform: 'translate(0%, 5%)' } },
      { selector: '.table', styles: { transform: 'translate(0%, 0%)' } },
      { selector: '.tableRows', styles: { transform: 'translate(0%, -35%)' } },
      { selector: '.trendChart', styles: { transform: 'translate(0%, 5%)' } },
      { selector: '.moveLeft', styles: { marginRight: '20px'} },
      { selector: '.moveRight', styles: { marginLeft: '20px'} },
      { selector: '.down', styles: { transform: 'translate(0%, 2%)' } },
      { selector: '.up', styles: { transform: 'translate(0%, -25%)' } },
      { selector: '.legendValue', styles: { transform: 'translate(0%, -40%)' } },
    ]

    const originalStyles = elementAdjustments.map(({ selector, styles }) => ({
      selector,
      elements: applyStyles(selector, styles),
      styles,
    }))

    const pdfBlob = await html2pdf().set(options).from(component).outputPdf('blob')
    originalStyles?.forEach(({ elements, styles }) => {
      elements?.forEach((el) => {
        Object.keys(styles).forEach((styleKey) => {
          el.style[styleKey as any] = ''
        })
      })
    })

    return pdfBlob
  }

  const handleDownload = async () => {
    const toastId = toast.info('Downloading report...', {
      style: { padding: '10px 20px' },
      autoClose: false,
      isLoading: true,
      progress: undefined,
    })
    setTimeout(() => {
      setIsDownloading(true)
    }, 0)

    try {
      if (totalDetailed === 2 && reportType === 1) {
        await getReportAsPDF({
          energy_type: energyType || null,
          location_ids: locations,
          device_type: deviceTypes || [],
          device_groups: groupId,
          from_date: startDate.toLocaleDateString('en-US'),
          to_date: endDate.toLocaleDateString('en-US'),
          device_ids: deviceIds,
          report_name: reportName,
          report_type: totalDetailed,
          // isBillingReport: selectedReportType === 2,
          // ibex_price: formData.ibexPrice,
        }).unwrap()
      } else {
        const pdfBlob = await handleClick()
        const pdfUrl = URL.createObjectURL(pdfBlob)
        const a = document.createElement('a')
        a.href = pdfUrl
        a.download = `${reportName}.pdf`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }

      toast.update(toastId, {
        render: 'The report has been downloaded successfully.',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      })
    } catch (error) {
      toast.update(toastId, {
        render: 'Failed to download report!',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      })
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        maxW={savedAs === ReportType.Pdf ? 840 : 1600}
        height={savedAs === ReportType.Pdf ? 800 : 'auto'}
        maxH={800}
        overflow={'hidden auto'}
        sx={{
          ...scrollBarStyle,
        }}
      >
        <ModalHeader
          pb={0}
          sx={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white' }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} pb={'0.5em'}>
            <Text>Preview document</Text>
            <Flex gap={5}>
              <SendEmail
                reportType={savedAs === ReportType.Pdf ? 'pdf' : 'csv'}
                reportName={reportName}
                reportData={reportData}
                handleClick={handleClick}
                disabled={isDataLoading || isCsvLoading || isDownloading}
                startDate={startDate}
                endDate={endDate}
              />
              <RecurringReports
                startDate={startDate}
                endDate={endDate}
                disabled={isDataLoading || isCsvLoading || isDownloading}
                locations={locations}
                deviceGroups={groupId}
                deviceTypes={deviceTypes}
                energyType={energyType}
                reportType={reportType}
                measurementTypes={measurementTypes}
                devices={deviceIds}
                totalDetailed={totalDetailed}
                ibexPrice={ibexIndex}
                carbonIntensity={carbonIntensity}
                reportName={reportName}
                filters_type="recurring"
                isDownloading={isDownloading}
                savedAs={savedAs === ReportType.Pdf ? 'pdf' : 'csv'}
              />
              <BookmarkReport
                startDate={startDate}
                endDate={endDate}
                disabled={isDataLoading || isCsvLoading || isDownloading}
                locations={locations}
                deviceGroups={groupId}
                deviceTypes={deviceTypes}
                energyType={energyType}
                reportType={reportType}
                measurementTypes={measurementTypes}
                devices={deviceIds}
                totalDetailed={totalDetailed}
                ibexPrice={ibexIndex}
                carbonIntensity={carbonIntensity}
                reportName={reportName}
                filters_type="bookmark"
                isDownloading={isDownloading}
                savedAs={savedAs === ReportType.Pdf ? 'pdf' : 'csv'}
              />
              <Button
                colorScheme="blue"
                size={'small'}
                sx={{ width: '150px' }}
                onClick={savedAs === ReportType.Pdf ? handleDownload : handleSendCsv}
                isLoading={isDataLoading || isCsvLoading || isDownloading}
              >
                <Text fontSize={'md'}>Download</Text>
              </Button>
            </Flex>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody height={'fit-content'} p={0}>
          <Stack
            height="100%"
            width="100%"
            sx={{ boxSizing: 'bored-box' }}
            id={'pdf-container'}
          >
            {reportType === 3 && savedAs === ReportType.Pdf && (
              <EnergyConsumptionReportPdf
                startDate={startDate}
                endDate={endDate}
                isDownloading={isDownloading}
                data={energyConsumptionReport}
              />
            )}
            {reportType === 1 && savedAs === ReportType.Pdf && (
              <TrendReportPdf
                data={pdfReportData}
                locations={locations}
                fromDate={startDate}
                toDate={endDate}
                reportPeriodType={reportPeriodType}
                totalDetailed={totalDetailed}
                isDownloading={isDownloading}
                isDataLoading={isTrendReportLoading}
                energyType={energyType}
              />
            )}
            {reportType === 2 && savedAs === ReportType.Pdf && (
              <BillingReportPdf
                data={billingReportData}
                locations={locations}
                fromDate={startDate}
                toDate={endDate}
                reportEnergyType={energyType}
                isDownloading={isDownloading}
              />
            )}
            {savedAs === ReportType.Csv && (
              <CsvPreviewTable isLoading={isCsvLoading} csvData={reportData} />
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PreviewDocumentDialog

import { Box, Flex, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import { LocationChart } from '../../features/dashboard/types'
import { addCommasToNumber } from '../../utils/helpers'

type TemperatureChartProps = {
  data: LocationChart.Data[]
  lineProps: { dataKey: string; type: string }
  period: string
  title: string
  measurementUnit: string
  icon?: JSX.Element
}

export const AverageLineChart = ({
  data,
  lineProps,
  period,
  title,
  measurementUnit,
  icon,
}: TemperatureChartProps) => {
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 })

  const viewData = useMemo(
    () =>
      data.map((item, _index) => {
        const datetime = new Date(item.time || item.hour || item.day || item.month)
        return {
          ...item,
          time:
            period === 'day' ? format(datetime, 'H:mm') : format(datetime, 'MMM d'),

          month: format(datetime, 'MMM d'),
          hours: format(datetime, 'H:mm'),
        }
      }),
    [data, period],
  )

  const toolTipPosition = React.useMemo<TooltipProps<number, string>['position']>(() => {
    if (mouseCoords) {
      const tooltipWidth = document.getElementById('custom-tooptip')?.offsetWidth

      return {
        x: mouseCoords.x - tooltipWidth / 2,
        y: mouseCoords.y - 155,
      }
    }
    return undefined
  }, [mouseCoords])

  const CustomAverageTooltip = useCallback(
    ({ active, payload }) => {
      if (active && payload && payload.length) {
        return (
          <Box id="custom-tooptip">
            <Box background="white" boxShadow="xl" rounded="2xl">
              <Flex direction="column" gap={2} p={4}>
                <Text fontSize="14px" color="text.secondary">
                  {title}
                </Text>
                <Flex alignItems="center">
                  <Text fontSize="20px" color="#484848" fontWeight={700}>
                    {addCommasToNumber(payload[0].value)}
                  </Text>
                  <Text fontSize="16px" color="#484848" ml="2">
                    {measurementUnit}
                  </Text>
                </Flex>
                <Flex>
                  <Text fontSize="16px" color="#484848" ml="2">
                    {period === 'day' || period === 'week'
                      ? `${payload[0].payload.month} ${payload[0].payload.hours}`
                      : payload[0].payload.time}
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Flex justifyContent="space-around">
              <Box
                mt="-1"
                height="0"
                width="0"
                borderLeft="15px solid transparent"
                borderRight="15px solid transparent"
                borderTop="20px solid white"
              />
            </Flex>
          </Box>
        )
      }
      return null
    },
    [measurementUnit, period, title],
  )

  const handleMouseMove = (e) => {
    if (e && e.chartX && e.chartY) {
      setMouseCoords({ x: e.chartX, y: e.chartY })
    }
  }

  return (
    <Box rounded="lg" bg="container.background.gray" px="20px" py="16px">
      <Box p={2}>
        <Text fontSize="16px" color="#484848" fontWeight={700}>
          {title}
        </Text>
      </Box>
      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart
          data={viewData}
          margin={{ top: 0, left: 0, right: 10, bottom: 0 }}
          onMouseMove={handleMouseMove}
        >
          <CartesianGrid strokeDasharray="4 4" />
          <Line
            type="monotone"
            dataKey={lineProps.dataKey}
            stroke="#181716"
            strokeWidth="3"
          />
          <XAxis
            allowDecimals
            dataKey="time"
            stroke="#ccc"
            style={{ fill: 'gray', fontSize: '11px', color: 'black' }}
          />
          <YAxis
            tickLine={false}
            tickFormatter={(value) => `${Math.round(value)} ${measurementUnit}`}
            width={40}
            stroke="#ccc"
            style={{ fill: 'gray', fontSize: '11px', color: 'black' }}
          />
          <Tooltip
            content={<CustomAverageTooltip />}
            position={toolTipPosition}
            allowEscapeViewBox={{ x: true, y: true }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}

import { Flex, Spinner, Stack } from "@chakra-ui/react"
import React from "react"
import { EnergyReportTitlePage } from "./EnergyReportComponents/EnergyReportTitlePage"
import { EnergyReportBody } from "./EnergyReportComponents/EnergyReportBody"
import backgroundImg from '../../../../assets/report/background.png'
import { EnergyConsumptionReport } from "../../api/types"

type Props = {
  startDate: Date;
  endDate: Date;
  data: EnergyConsumptionReport,
  isDownloading: boolean
}

export const EnergyConsumptionReportPdf = ({ data, startDate, endDate, isDownloading }: Props) => {
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      backgroundImage={`url('${backgroundImg}')`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="left"
    >
      <Flex direction="column" justifyContent="center" alignItems="center" width="100%" height="100%">
        {isDownloading ? (
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        ) :
          <>
            <EnergyReportTitlePage startDate={startDate} endDate={endDate} locations={data} />
            <EnergyReportBody startDate={startDate} endDate={endDate} locations={data} />
          </>}
      </Flex>
    </Stack>
  )
}
import { Stack } from '@chakra-ui/react'
import { IReportResult } from '../../../../api/trendReportPdf'
import { ReportPeriodType } from '../../../GenerateReport'
import ReportPdfMonths from './ReportPdfMonts'
import ReportPdfDays from './ReportPdfDays'

type PdfTableProps = {
  data: IReportResult[]
  reportPeriodType: ReportPeriodType
}

export const PdfTable = (props: PdfTableProps) => {
  const { data, reportPeriodType } = props

  return (
    <Stack width={764} className="table">
      {reportPeriodType === ReportPeriodType.Month ? (
        <ReportPdfMonths data={data} />
      ) : (
        <ReportPdfDays data={data} />
      )}
    </Stack>
  )
}

import React from 'react'
import { IReportResult } from '../../../../api/trendReportPdf'
import { ReportPeriodType } from '../../../GenerateReport'
import TrendChartJsHours from './TrendChartJsDays'
import TrendChartJsMonth from './TrendChartJsMonth'

type GenerateTrendChartProps = {
  data: IReportResult[]
  reportPeriodType: ReportPeriodType
  reportEnergyType: string
}

export const GenerateTrendChart = (props: GenerateTrendChartProps) => {
  const { data, reportPeriodType, reportEnergyType } = props

  return reportPeriodType === ReportPeriodType.Month ? (
    <TrendChartJsMonth data={data} reportEnergyType={reportEnergyType} />
  ) : (
    <TrendChartJsHours data={data} reportEnergyType={reportEnergyType} />
  )
}

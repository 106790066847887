import { Text } from '@chakra-ui/react'

type Props = {
  text: string
}

export const BillingTableHeaderText = ({ text }: Props) => {
  return (
    <Text width="100%" alignContent="center" maxWidth="80%" fontWeight={700} fontSize={15} p={1}>
      {text}
    </Text>
  )
}

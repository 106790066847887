import { Box } from "@chakra-ui/react"
import React from "react"
import { IBillingReport } from "../../../api/types"
import { NewPageHeader } from "../PdfComponents/NewPageHeader"
import { BillingReportTable } from "./BillingReportTable"

type Props = {
  data: IBillingReport[],
  reportStartDate: string,
  reportEndDate: string,
  classification: number,
}
export const BillingReportBody = ({ data, reportStartDate, reportEndDate, classification }: Props) => {
  return (
    <Box width="100%" mt={20} p={5}>
      {
        data.map((x, index) => {
          return (
            <Box key={`trend-body-${index}`}>
              <Box>
                <Box className="trendChart" width="100%">
                  <NewPageHeader
                    reportType={x.location_name}
                    period={`${reportStartDate} - ${reportEndDate}`}
                  />
                </Box>
              </Box>
              <Box style={{ margin: '20px 0' }}>
                <Box>
                  <BillingReportTable classification={classification} data={x.data} />
                </Box>
              </Box>
            </Box>
          )
        })
      }
    </Box>)
}
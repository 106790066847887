import { Box } from '@chakra-ui/react'
import React from 'react'
import { IReportResult } from '../../../../api/trendReportPdf'
import TableMonths from './TableMonths'

type ReportPdfMonthsProps = {
  data: IReportResult[]
}

const ReportPdfMonths = (props: ReportPdfMonthsProps) => {
  const { data } = props

  const numberOfMonths = data.length

  let tablesData: Array<Array<IReportResult>> = []

  if (numberOfMonths > 3) {
    const chunkSize = 3
    for (let i = 0; i < numberOfMonths; i += chunkSize) {
      tablesData.push(data.slice(i, i + chunkSize))
    }
  } else {
    tablesData = [data]
  }

  return (
    <>
      {tablesData.map((result, index) => (
        <Box key={`table-${index}`} style={{ boxSizing: 'border-box', width: '100%' }}>
          <TableMonths data={result} isLastTable={index === tablesData.length - 1} />
        </Box>
      ))}
    </>
  )
}

export default ReportPdfMonths

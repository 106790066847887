import { Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { IReportResult } from '../../../../api/trendReportPdf'

type TableMonthsProps = {
  data: IReportResult[]
  isLastTable?: boolean
}

const TableMonths = (props: TableMonthsProps) => {
  const { data, isLastTable = true } = props

  return (
    <>
      <Stack width={'100%'} border="1px solid black" className='down'>
        <Stack
          width={'100%'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent="space-between"
          borderBottom={'1px solid black'}
          margin={0}
          padding={1}
        >
          <Stack
            style={{
              width: '40px',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <Text className="tableRows" fontFamily={'Inter'} fontWeight={700}>
              Day
            </Text>
          </Stack>
          {data.map((item, idx) => (
            <Stack
              mt={'0 !important'}
              style={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                className="tableRows"
                key={`month-header-${idx}`}
                fontFamily={'Inter'}
                fontWeight={700}
                lineHeight={'100%'}
                fontSize={'1.25rem'}
              >
                {item.date}
              </Text>
            </Stack>
          ))}
        </Stack>

        <Stack sx={{ margin: 0 }} height={'100%'}>
          {Array.from({ length: 31 }).map((_, dayIndex) => (
            <Stack
              flexDirection={'row'}
              key={`day-${dayIndex + 1}`}
              borderBottom={dayIndex + 1 !== 31 && '1px solid black'}
              height={'100%'}
              sx={{ margin: 0 }}
            >
              <Stack
                width={'40px'}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 0,
                }}
              >
                <Text className="tableRows">{dayIndex + 1}</Text>
              </Stack>
              <Stack
                flexDirection={'row'}
                width={'100%'}
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: 0,
                }}
              >
                {data.map((item, i) => {
                  const consumptionForDay = item.period.find(
                    (p) => Number(p.period.split('-')[2]) === dayIndex + 1,
                  )
                  return (
                    <Stack
                      style={{
                        margin: 0,
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        borderLeft: '1px solid black',
                      }}
                    >
                      <Text className="tableRows" key={`day-${dayIndex + 1}-month-${i}`}>
                        {consumptionForDay ? ` ${consumptionForDay.value} kWh` : 'N/A'}
                      </Text>
                    </Stack>
                  )
                })}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </>
  )
}

export default TableMonths

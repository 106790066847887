import {
  Button,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import React from 'react'
import { useReportFilterContext } from '../../../../../context/ReportFilterContext'
import ReportTemplateHistoryGrid from './ReportTemplateHistoryGrid'

export const GenerateReportHistoryTabs = () => {
  const { bookmarkTemplateCount, recurringTemplateCount } = useReportFilterContext()
  return (
    <Tabs bg="white" colorScheme="white" isFitted variant="enclosed">
      <TabList mb="1em">
        <Tab value="bookmark">Bookmark reports</Tab>
        <Tab value="recurring">Recurring reports</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <ReportTemplateHistoryGrid reportType="bookmark" />
          {bookmarkTemplateCount / 7 > 1 && (
            <GridItem
              colSpan={2}
              py="24px"
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button as="a" href="/reports/history">
                View All
              </Button>
            </GridItem>
          )}
        </TabPanel>
        <TabPanel>
          <ReportTemplateHistoryGrid reportType="recurring" />
          {recurringTemplateCount / 7 > 1 && (
            <GridItem
              colSpan={2}
              py="24px"
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button as="a" href="/reports/history">
                View All
              </Button>
            </GridItem>
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

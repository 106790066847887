import { pythonApi } from '../../../app/services/api/api'
import { getDashboardOverviewMockData } from '../../../mockData/dashboard'
import { formatDateString } from '../../../utils/helpers'
import { ReportData, ReportHistoryData } from '../types'
import { ILocationResponse } from './locations'
import { IReportTotalResult, ITrendReport } from './trendReportPdf'
import { EnergyConsumptionReport, IBillingLocationReport } from './types'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLocations: builder.query<ILocationResponse, void>({
      query: () => ({
        url: `/locations/`,
        method: 'GET',
      }),
      providesTags: ['List'],
      transformResponse: (response: ILocationResponse, _meta, arg): ILocationResponse => {
        const mockResponse = getDashboardOverviewMockData()
        return mockResponse ? (mockResponse as unknown as ILocationResponse) : response
      },
    }),
    getReportData: builder.query<ReportData.Data, ReportData.QueryParams>({
      query: ({ from_date, to_date, location_ids }) => {
        const searchParams = new URLSearchParams()
        location_ids.forEach((id) => searchParams.append('location_ids', id))
        searchParams.append('from_date', from_date)
        searchParams.append('to_date', to_date)
        return {
          url: `/locations/report-data/?${searchParams}`,
          method: 'GET',
        }
      },
    }),
    getReportsHistory: builder.query<
      ReportHistoryData.Data,
      { page: number; location?: number; filterType?: string }
    >({
      query: ({ page, location, filterType }) => {
        const queryParams = new URLSearchParams()
        queryParams.set('page', page?.toString())
        location && queryParams.set('locations', location?.toString())
        filterType && queryParams.set('filterType', filterType)

        return {
          url: `/reports/?${queryParams.toString()}`,
          method: 'GET',
        }
      },
      providesTags: ['ReportData'],
    }),
    getTrendReportData: builder.mutation<IReportTotalResult, ITrendReport>({
      query: (body) => ({
        url: `/product-profile/`,
        method: 'POST',
        body,
      }),
    }),
    getBillingReport: builder.mutation<IBillingLocationReport[], ITrendReport>({
      query: (body) => ({
        url: `/billing-report/`,
        method: 'POST',
        body,
      }),
    }),
    getEnergyConsumptionReport: builder.mutation<
      EnergyConsumptionReport,
      ReportData.QueryParams
    >({
      query: (body) => ({
        url: `/energy-consumption/`,
        method: 'POST',
        body,
      }),
    }),
    createRecurringReport: builder.mutation<any, ReportData.RecurringReportParams>({
      query: (props: ReportData.RecurringReportParams) => {
        const queryParams = new URLSearchParams()
        const fromDate = formatDateString(props.startDate)
        const toDate = formatDateString(props.endDate)
        queryParams.set('location_ids', props.locations)
        queryParams.set('from_date', fromDate)
        queryParams.set('to_date', toDate)
        queryParams.set('energy_types', props.energyType)
        queryParams.set('report_type', props.reportType)
        queryParams.set('device_ids', props.devices.join(','))
        queryParams.set('report_name', props.reportName)
        queryParams.set('filters_type', props.filters_type)
        queryParams.set('saved_as', props.savedAs)

        if (props.deviceGroups) {
          queryParams.set('groups_ids', props.deviceGroups)
        }
        if (props.deviceTypes) {
          queryParams.set('device_types', props.deviceTypes)
        }
        if (props.measurementTypes) {
          queryParams.set('measurement_types', props.measurementTypes)
        }
        if (props.totalDetailed) {
          queryParams.set('total_detailed', props.totalDetailed)
        }
        if (props.ibexPrice) {
          queryParams.set('ibex_price', props.ibexPrice)
        }
        if (props.carbonIntensity) {
          queryParams.set('carbon_intensity', props.carbonIntensity)
        }
        if (props.frequency) {
          queryParams.set('frequency', props.frequency)
        }
        if (props.sendOn) {
          queryParams.set('send_on', props.sendOn)
        }
        if (props.sendTo) {
          queryParams.set('send_to', props.sendTo)
        }

        return {
          url: `/reports/?${queryParams.toString()}`,
          method: 'POST',
          body: queryParams,
        }
      },
      invalidatesTags: ['ReportData'],
    }),
    getRecurringReport: builder.query<any, ReportData.RecurringReportParams>({
      query: (props: ReportData.RecurringReportParams) => {
        const queryParams = new URLSearchParams()
        const fromDate = formatDateString(props.startDate)
        const toDate = formatDateString(props.endDate)
        queryParams.set('location_ids', props.locations)
        queryParams.set('from_date', fromDate)
        queryParams.set('to_date', toDate)
        queryParams.set('energy_types', props.energyType)
        queryParams.set('report_type', props.reportType)
        queryParams.set('device_ids', props.devices.join(','))
        queryParams.set('report_name', props.reportName)
        queryParams.set('filters_type', props.filters_type)
        queryParams.set('saved_as', props.savedAs)

        if (props.deviceGroups) {
          queryParams.set('groups_ids', props.deviceGroups)
        }
        if (props.deviceTypes) {
          queryParams.set('device_types', props.deviceTypes)
        }
        if (props.measurementTypes) {
          queryParams.set('measurement_types', props.measurementTypes)
        }
        if (props.totalDetailed) {
          queryParams.set('total_detailed', props.totalDetailed)
        }
        if (props.ibexPrice) {
          queryParams.set('ibex_price', props.ibexPrice)
        }
        if (props.carbonIntensity) {
          queryParams.set('carbon_intensity', props.carbonIntensity)
        }
        if (props.frequency) {
          queryParams.set('frequency', props.frequency)
        }
        if (props.sendOn) {
          queryParams.set('send_on', props.sendOn)
        }
        if (props.sendTo) {
          queryParams.set('send_to', props.sendTo)
        }

        return {
          url: `/get-bookmarked-report/?${queryParams.toString()}`,
          method: 'GET',
        }
      },
    }),
    updateRecurringReport: builder.mutation<
      any,
      { body: ReportHistoryData.ReportHistory }
    >({
      query: ({ body }) => {
        return {
          url: `/reports/${body.id}/`,
          method: 'PATCH',
          body: body,
        }
      },
      invalidatesTags: ['ReportData'],
    }),
    deleteBookmark: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/reports/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ReportData'],
    }),
  }),
})

export const {
  useGetAllLocationsQuery,
  useGetReportDataQuery,
  useGetReportsHistoryQuery,
  useGetTrendReportDataMutation,
  useGetEnergyConsumptionReportMutation,
  useGetRecurringReportQuery,
  useCreateRecurringReportMutation,
  useDeleteBookmarkMutation,
  useGetBillingReportMutation,
  useUpdateRecurringReportMutation,
} = extendedApi
export default extendedApi

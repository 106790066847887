import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  ChartConfiguration,
} from 'chart.js'
import { Stack } from '@chakra-ui/react'
import { IReportResult } from '../../../../api/trendReportPdf'

// Register Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
)

type TrendChartMonthsProps = {
  data: IReportResult[]
  reportEnergyType: string
}

const DAY = Array.from({ length: 31 }, (_, i) => i + 1)

const TrendChartJsMonth = (props: TrendChartMonthsProps) => {
  const { data, reportEnergyType } = props

  // Process the structured data
  const structuredData = data.map((monthData) => {
    const { date, period } = monthData
    const filteredData = period.map((dayData) => ({
      day: parseInt(dayData.period.slice(-2)),
      value: parseFloat(dayData.value.toString()),
    }))
    return {
      date,
      data: filteredData,
    }
  })

  // Configure the datasets for Chart.js
  const datasets = structuredData.map((monthData, index) => ({
    label: monthData.date,
    data: monthData.data.map((d) => ({ x: d.day, y: d.value })),
    borderColor: `hsl(${(index * 360) / data.length}, 70%, 60%)`,
    backgroundColor: `hsl(${(index * 360) / data.length}, 70%, 60%)`,
    borderWidth: 1,
    fill: false,
  }))

  const chartConfig: ChartConfiguration<'line'> = {
    type: 'line',
    data: {
      labels: DAY,
      datasets,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      devicePixelRatio: 2,
      scales: {
        x: {
          min: 0,
          max: 31,
          title: {
            display: true,
            text: 'Day of the Month',
          },
        },
        y: {
          beginAtZero: false,
          title: {
            display: true,
            text: `${reportEnergyType} (kWh)`,
          },
        },
      },
    },
  }

  return (
    <Stack width={764} height="600px" mb="24px" borderRadius="0.25em">
      <div style={{ width: '100%', height: '100%' }}>
        <Line {...chartConfig} />
      </div>
    </Stack>
  )
}

export default TrendChartJsMonth

import { HStack, VStack } from '@chakra-ui/react'
import React from 'react'
import { Analysis } from '../../api/types'
import { Cell } from './Cell'

type Props = {
  items: Record<string, { time: Date; avg_consumption: number; avg_generated_power: number; }[]> | undefined
  pointerEvents: any
  consumptionRates: Analysis.Rates
  heatMapKey: string
}

const Grid: React.FC<Props> = ({
  items,
  pointerEvents,
  consumptionRates,
  heatMapKey,
}) => {

  return (
    <VStack
      spacing={0}
      overflow="hidden"
      zIndex={1}
      sx={{ pointerEvents: pointerEvents }}
      border="dimgray"
    >
      {Object.entries(items).map(([date, entries], idx) => (
        <React.Fragment key={idx}>
          <HStack spacing={0} w="full">
            {entries.map((entry, entryIdx) => (
              <Cell
                key={entry.time.toLocaleString()}
                heatMapKey={heatMapKey}
                item={{
                  idx: `${entryIdx}`,
                  hour: new Date(entry.time).getHours(),
                  day: date,
                  consumption:
                    entry[heatMapKey] <= 0.001 && entry[heatMapKey] !== null ? 0 : entry[heatMapKey],
                  consumptionRates,
                }}
              />
            ))}
          </HStack>
        </React.Fragment>
      ))}
    </VStack>
  )
}

export default Grid

import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Tooltip,
    Box,
} from '@chakra-ui/react';

export default function CustomModal({ children, title, handleSend, isOpen, onClose, isDisabled, isLoading, buttonName, tooltipLabel }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody sx={{ pb: 0 }}>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <Tooltip label={tooltipLabel} hasArrow isDisabled={!isDisabled} placement="top">
                        <Box width="100%">
                            <Button
                                disabled={isDisabled}
                                isLoading={isLoading}
                                padding="0"
                                width="100%"
                                onClick={handleSend}
                                colorScheme="blue"
                                pointerEvents={isDisabled ? 'none' : 'auto'}
                            >
                                {buttonName}
                            </Button>
                        </Box>
                    </Tooltip>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
import { Box, Button, HStack, Image, Skeleton, VStack } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { DASHBOARDPARAMS } from '../consts'
import { useQueryParams } from '../hooks/useQueryParams'
import { DashboardOverviewLocation, Title } from '../types'
import emptyRoomImage from '../../../assets/empty-room.jpg'
import { addCommasToNumber } from '../../../utils/helpers'

type Props = {
  location: Title & DashboardOverviewLocation
}

export const Card: React.FC<Props> = ({
  location: { title, id, current_usage, price_unit, energy_data, image },
}) => {
  const [, setSearchParams] = useSearchParams()
  const [queryParams] = useQueryParams(DASHBOARDPARAMS)

  return (
    <Box maxWidth="320px" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Image
        fallbackSrc=""
        src={image || emptyRoomImage}
        //TODO needs to be removed
        // src={
        //   image ? require(`../images/locations/${image}.png`) : emptyRoomImage
        // }
        alt={title}
        w="xs"
        fallback={<Skeleton w="full" h="full" />}
      />

      <Box display="flex" flexDirection="column" p="1rem" alignContent="flex-start">
        <Box fontSize="xl" fontWeight="bold" my="1rem">
          {title}
        </Box>
        <Box fontSize="sm" color="text.light">
          Total Consumption today /
          <Box as="span" textTransform="uppercase">
            BGN
          </Box>
        </Box>
        <Box fontSize="md" color="text.dark" mb="1rem">
          {`${addCommasToNumber(
            energy_data.total_consumption,
          )} ${price_unit} / ${addCommasToNumber(energy_data.total_consumption)}`}
        </Box>
        <HStack justify="space-between" w="full">
          <VStack spacing={0} align="flex-start">
            <Box fontSize="sm" color="text.intermediate">
              Current usage
            </Box>
            <Box>
              {addCommasToNumber(current_usage || 0)} {price_unit}
            </Box>
          </VStack>
          <Button
            size="sm"
            colorScheme="button.primary"
            onClick={() =>
              setSearchParams({
                ...queryParams,
                ...{ location: id.toString() },
              })
            }
          >
            Details
          </Button>
        </HStack>
      </Box>
    </Box>
  )
}

import {
  Box,
  Grid,
  GridItem,
  Divider,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { KILOWATT_HOUR } from '../../../../../utils/consts'
import { addCommasToNumber } from '../../../../../utils/helpers'
import { IBillingReportData } from '../../../api/types'
import { BillingTableHeaderText } from './BillingTableHeaderText'

type Props = {
  classification: number
  data: IBillingReportData[]
}

function calculateTotalEnergy(data) {
  const excludeIsMain = data.filter((device) => !device.device_is_main);

  const totalEnergy = excludeIsMain.reduce((sum, device) => {
    const energyValue = device.device_consumption ?? device.device_generated_power 
    return sum + (energyValue || 0)
  }, 0)

  return totalEnergy
}

function calculateTotalCost(data) {
  const excludeIsMain = data.filter((device) => !device.device_is_main)
  const totalCost = excludeIsMain.reduce((sum, device) => sum + device.device_total_cost, 0)
  return totalCost
}

export const BillingReportTable = ({ classification, data }: Props) => {
  let energyType = ''

  switch (classification) {
    case 1:
      energyType = 'Consumption'
      break
    case 2:
      energyType = 'Generated'
      break
    default:
      break
  }

  const totalEnergy = calculateTotalEnergy(data)
  const totalCost = calculateTotalCost(data)

  return (
    <Box overflowX="auto" border="1px" borderColor="black.300" borderRadius="md">
      <Grid templateColumns="repeat(1, 1fr)" bg="gray.50">
        <Grid templateColumns="repeat(15, 1fr)">
          <GridItem colSpan={3}>
            <BillingTableHeaderText text="Device" />
          </GridItem>
          <GridItem borderLeft="1px" colSpan={2}>
            <BillingTableHeaderText text="Type" />
          </GridItem>
          <GridItem borderLeft="1px" colSpan={2}>
            <BillingTableHeaderText text="Group" />
          </GridItem>
          <GridItem borderLeft="1px" colSpan={3}>
            <BillingTableHeaderText text={`Price (BGN/${KILOWATT_HOUR})`} />
          </GridItem>
          <GridItem borderLeft="1px" colSpan={3}>
            <BillingTableHeaderText text={`${energyType} (${KILOWATT_HOUR})`} />
          </GridItem>
          <GridItem borderLeft="1px" colSpan={2}>
            <BillingTableHeaderText text="Total Cost (BGN)" />
          </GridItem>
        </Grid>

        {data.map((row, index) => (
          <Grid
            key={`billing-table-row-${index}`}
            templateColumns="repeat(15, 1fr)"
            fontSize="sm"
            bg={index % 2 === 0 ? "white" : "gray.50"}
          >
            <GridItem colSpan={3} alignContent="center">
              <Text p={2} whiteSpace="normal" wordBreak="break-word">
                {row.device_name}
              </Text>
            </GridItem>
            <GridItem colSpan={2} borderLeft="1px" alignContent="center">
              <Text p={2} whiteSpace="normal" wordBreak="break-word">
                {row.device_type_name}
              </Text>
            </GridItem>
            <GridItem colSpan={2} borderLeft="1px" alignContent="center">
              <Text p={2} whiteSpace="normal" wordBreak="break-word">
                {row.device_group_name}
              </Text>
            </GridItem>
            <GridItem colSpan={3} borderLeft="1px" alignContent="center">
              <Text p={2}>
                {addCommasToNumber(row.device_total_cost / row.device_consumption)}
              </Text>
            </GridItem>
            <GridItem colSpan={3} borderLeft="1px" alignContent="center">
              <Text p={2}>
                {`${addCommasToNumber(row.device_consumption)} ${KILOWATT_HOUR}`}
              </Text>
            </GridItem>
            <GridItem colSpan={2} borderLeft="1px" alignContent="center">
              <Text p={2}>{addCommasToNumber(row.device_total_cost)}</Text>
            </GridItem>
            <GridItem colSpan={15}>
              <Divider borderColor="black.600" variant="solid" />
            </GridItem>
          </Grid>
        ))}
        <Grid
          templateColumns="repeat(15, 1fr)"
          fontSize="sm"
          bg="white"
          fontWeight="bold"
        >
          <GridItem colSpan={10} textAlign="right" p={2}>
            Total:
          </GridItem>
          <GridItem colSpan={3} textAlign="center" borderLeft="1px">
            <Text p={2}>{`${addCommasToNumber(totalEnergy)} ${KILOWATT_HOUR}`}</Text>
          </GridItem>
          <GridItem colSpan={2} textAlign="center" borderLeft="1px">
            <Text p={2}>{addCommasToNumber(totalCost)} BGN</Text>
          </GridItem>
        </Grid>
      </Grid>
    </Box>
  )
}

import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  ChartConfiguration,
} from 'chart.js'
import { Stack } from '@chakra-ui/react'
import { IReportResult } from '../../../../api/trendReportPdf'

// Register required Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
)

type TrendChartHoursProps = {
  data: IReportResult[]
  reportEnergyType: string
}

type HourlyData = {
  hour: number
  value: number | null
}

const generateHourlyData = (result: IReportResult): HourlyData[] => {
  return Array.from({ length: 24 }, (_, i) => {
    const hourLabel = i
    const hourData = result.period.find((p) => p.period === `${String(hourLabel)}:00`)

    return {
      hour: hourLabel,
      value: hourData ? hourData.value : null,
    }
  })
}

const TrendChartJsDays = (props: TrendChartHoursProps) => {
  const { data, reportEnergyType } = props

  // Determine labels based on `differenceInDays`
  const labels = Array.from({ length: 24 }, (_, i) => `${i}:00`)

  const datasets = data.map((dayData, index) => {
    const hourlyData = generateHourlyData(dayData)

    return {
      label: dayData.date,
      data: hourlyData.map((hour) => ({
        x: hour.hour,
        y: hour.value || 0, // Replace null with 0 for visualization
      })),
      borderColor: `hsl(${(index * 360) / data.length}, 70%, 60%)`,
      backgroundColor: `hsl(${(index * 360) / data.length}, 70%, 60%)`,
      borderWidth: 1,
      fill: false,
    }
  })

  const chartConfig: ChartConfiguration<'line'> = {
    type: 'line',
    data: {
      labels,
      datasets,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        }, 
      },
      scales: {
        x: {
          type: 'linear',
          title: {
            display: true,
            text: 'Hour of the Day',
          },
          ticks: {
            stepSize: 1,
            callback: (value: number) => `${value}:00`,
          },
        },
        y: {
          title: {
            display: true,
            text: `${reportEnergyType} (kWh)`,
          },
          beginAtZero: false, 
        },
      },
    },
  }

  return (
    <Stack width={764} height="600px" direction="row" mb="24px" borderRadius="0.25em">
      <Line {...chartConfig} />
    </Stack>
  )
}

export default TrendChartJsDays
